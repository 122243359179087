<template>
  <div id="services">
    <HeadSections
      class="services-img"
      :TitlePage="$route.name"
      :TitleSec="$t('ServicesHeaderDescription')"
      :PathPageFrom="$t('Home')"
      :PathPageTo="$t('Services')"
      :pathFrom="`/`"
    />
    <div class="container">
      <IndexService/>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Services Page',
  components: {
    HeadSections: defineAsyncComponent(() => import('@/components/Global/HeadSections.vue')),
    IndexService: defineAsyncComponent(() => import('@/components/Services/IndexService.vue')),
  },
}
</script>
